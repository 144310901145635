import Icon from 'components/Icon';
import classNames from 'classnames';
import Link from 'components/Link';
import Linker from 'components/Linker';
import useOs from 'lib/hooks/useOs';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';

const Button = ({
    id,
    codename,
    element_type = 'button',
    btn_style,
    btn_size,
    icon_position,
    disabled,
    children,
    block,
    className,
    type,
    trigger,
    cta_link,
    icon,
    target,
    operating_system,
    locale,
    intrinsic,
}) => {
    const osMatch = useOs({ operating_system });
    const ElementType = element_type === 'button' ? 'button' : element_type;
    const router = useRouter();
    const callbackUrl =
        router?.query?.callbackUrl ||
        `/${router.locale === 'default' ? '' : router.locale}${router?.asPath}`;
    function handleClick(e) {
        if (!trigger) return;
        try {
            switch (trigger) {
                case 'login_azure_ad':
                    return signIn('azure-ad', { callbackUrl });
                case 'print_press_release':
                    var press_release_title = document.getElementById(
                        'press-release-title'
                    ).innerHTML;
                    var press_release_body =
                        document.getElementById('press-release-body').innerHTML;
                    var printWindow = window.open(
                        '',
                        '',
                        'height=500, width=500'
                    );
                    printWindow.document.write('<html>');
                    printWindow.document.write(
                        '<style> img {max-width:100%} figure {text-align:center}</style>'
                    );
                    printWindow.document.write('<body >');
                    printWindow.document.write(
                        `<h1>${press_release_title}</h1>`
                    );
                    printWindow.document.write(press_release_body);
                    printWindow.document.write('</body></html>');
                    printWindow.document.close();
                    return printWindow.print();
                default:
                    return trigger(e);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {cta_link && btn_style !== 'linker' && (
                <Link
                    href={cta_link}
                    className={classNames('btn', {
                        [`${className}`]: className,
                        'btn-primary':
                            btn_style === null || btn_style === undefined,
                        [`btn-${btn_style}`]: btn_style,
                        [`btn-${btn_size}`]: btn_size && btn_size !== 'md',
                        'btn-block': block,
                        'btn--disabled': disabled,
                        'btn--os-disabled': operating_system && !osMatch,
                        'btn--intrinsic': intrinsic,
                    })}
                    disabled={disabled}
                    type={type}
                    onClick={trigger}
                    key={Date.now()}
                    data-kontent-item-id={id}
                    data-kontent-element-codename={codename}
                    target={target}
                    locale={locale}
                >
                    {icon_position === 'left' && icon && (
                        <Icon
                            key={Date.now()}
                            icon={icon}
                            className={classNames({ 'mr-2': children })}
                        />
                    )}
                    {children}
                    {icon_position === 'right' && icon && (
                        <Icon
                            key={Date.now()}
                            icon={icon}
                            className={classNames({ 'ml-2': children })}
                        />
                    )}
                </Link>
            )}

            {cta_link && btn_style === 'linker' && (
                <Linker
                    className={className}
                    id={id}
                    codename={codename}
                    cta_link={cta_link}
                    btn_size={btn_size}
                    target={target}
                >
                    {children}
                </Linker>
            )}

            {!cta_link && (
                <ElementType
                    className={classNames('btn', {
                        [`${className}`]: className,
                        'btn-primary':
                            btn_style === null || btn_style === undefined,
                        [`btn-${btn_style}`]: btn_style,
                        [`btn-${btn_size}`]: btn_size && btn_size !== 'md',
                        'btn-block': block,
                        'btn--disabled': disabled,
                    })}
                    disabled={disabled}
                    type={type}
                    onClick={handleClick}
                    data-kontent-item-id={id}
                    data-kontent-element-codename={codename}
                >
                    {icon_position === 'left' && icon && (
                        <Icon
                            icon={icon}
                            className={classNames({ 'mr-2': children })}
                        />
                    )}
                    {children}
                    {icon_position === 'right' && icon && (
                        <Icon
                            icon={icon}
                            className={classNames({ 'ml-2': children })}
                        />
                    )}
                </ElementType>
            )}
        </>
    );
};

export default Button;
